import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./WorkCarousel.scss";
import Work from "../Work/Work";
import screenshot from "../../assets/images/laptop.svg";
import group2 from "../../assets/images/group2.svg";
import group from "../../assets/images/group.svg";
import screenshotAshley from "../../assets/images/laptop-ashley-1.svg";
import screenshotAshley2 from "../../assets/images/laptop-ashley-2.svg";
import group2Ashley from "../../assets/images/group2-ashley.svg";
import groupAshley from "../../assets/images/group-ashley.svg";
import screenshotTask from "../../assets/images/laptop-task.svg";
import screenshotTask2 from "../../assets/images/laptop-task-2.svg";
import group2Task from "../../assets/images/group2-task.svg";
import groupTask from "../../assets/images/group-task.svg";
import screenshotArcca from "../../assets/images/laptop-arcca.svg";
import screenshotArcca2 from "../../assets/images/laptop-arcca2.svg";
import group2Arcca from "../../assets/images/group2-arcca.svg";
import groupArcca from "../../assets/images/group-arcca.svg";

import {
  projectBlink,
  projectAshley,
  projectTask,
  projectArcca,
} from "../../assets/data/data";

export default function WorkCarousel({
  toggleDarkMode,
  show,
  handleClose,
  handleShow,
  alt,
}) {
  const blinkImages = [screenshot, group, group2];
  const ashleyImages = [
    screenshotAshley,
    screenshotAshley2,
    groupAshley,
    group2Ashley,
  ];
  const taskImages = [screenshotTask, screenshotTask2, groupTask, group2Task];
  const arccaImages = [
    screenshotArcca,
    screenshotArcca2,
    groupArcca,
    group2Arcca,
  ];

  const settings = {
    dots: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 10000,
  };

  return (
    <div
      className={
        toggleDarkMode ? "workcarousel workcarousel--alt" : "workcarousel"
      }
    >
      <Slider {...settings}>
        <div>
          <Work
            toggleDarkMode={toggleDarkMode}
            show={show}
            handleShow={handleShow}
            handleClose={handleClose}
            imageArray={arccaImages}
            project={projectArcca}
            alt="arcca"
            keyId="4"
          />
        </div>
        <div>
          <Work
            toggleDarkMode={toggleDarkMode}
            show={show}
            handleShow={handleShow}
            handleClose={handleClose}
            imageArray={taskImages}
            project={projectTask}
            alt="task"
            keyId="1"
          />
        </div>
        <div>
          <Work
            toggleDarkMode={toggleDarkMode}
            show={show}
            handleShow={handleShow}
            handleClose={handleClose}
            imageArray={ashleyImages}
            project={projectAshley}
            alt="ashley"
            keyId="2"
          />
        </div>
        <div>
          <Work
            toggleDarkMode={toggleDarkMode}
            show={show}
            handleShow={handleShow}
            handleClose={handleClose}
            imageArray={blinkImages}
            project={projectBlink}
            alt="blink"
            keyId="3"
          />
        </div>
      </Slider>
    </div>
  );
}
