import "../App.scss";
import "./Video.scss";
import { useState, useEffect } from "react";

import React from "react";
import { ThemeProvider } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import "@fontsource-variable/inter";

import Work from "../components/Work/Work";
import { useNavigate } from "react-router-dom";
import Header from "../components/Header/Header";
import Profile from "../components/Profile/Profile";
import screenshot from "../assets/images/laptop.svg";
import group2 from "../assets/images/group2.svg";
import group from "../assets/images/group.svg";

import { projectBlink } from "../assets/data/data";
export default function Video({
  toggleDarkMode,
  toggleDarkTheme,
  darkPrefsCheck,
  darkTheme,
  dimensions,
}) {
  const [show, setShow] = useState(true);
  const blinkImages = [screenshot, group, group2];
  const nav = useNavigate();

  const handleShow = () => {
    setShow(true);
  };
  const handleClose = () => {
    setShow(false);
    nav("/", { replace: true });
  };

  useEffect(() => {
    window.addEventListener("wheel", handleClose);
    darkPrefsCheck();

    return () => {
      window.removeEventListener("wheel", handleClose);
    };
  }, []);

  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />
      <div className={toggleDarkMode ? "app" : "app app--alt"}>
        <Header
          toggleDarkTheme={toggleDarkTheme}
          toggleDarkMode={toggleDarkMode}
          dimensions={dimensions}
          handleClose={handleClose}
          activeSection="work"
        />
        {dimensions.width > 768 && (
          <div
            className={
              toggleDarkMode
                ? "app__container"
                : "app__container app__container--alt"
            }
          >
            <Profile toggleDarkMode={toggleDarkMode} />
          </div>
        )}
        <div className="app__page" onWheel={handleClose}>
          <div className="app__sections-container">
            <div
              className={
                show
                  ? "section app__section app__section--work app__section--alt"
                  : "section app__section app__section--work "
              }
              data-anchor="work"
            >
              <Work
                toggleDarkMode={toggleDarkMode}
                show={show}
                handleShow={handleShow}
                handleClose={handleClose}
                imageArray={blinkImages}
                project={projectBlink}
                alt="blink"
              />
            </div>
          </div>
        </div>
      </div>
    </ThemeProvider>
  );
}
