import "./Work.scss";
import {
  XCircleIcon,
  EyeIcon,
  CodeBracketIcon,
} from "@heroicons/react/24/solid";

import ImageCarousel from "../Carousel/Carousel";

function Work({
  toggleDarkMode,
  show,
  handleShow,
  handleClose,
  imageArray,
  project,
  alt,
  keyId,
}) {
  const renderTech = () => {
    return (
      <>
        {project.stack.map((tech, i) => (
          <li
            className={
              toggleDarkMode
                ? `work__list-item work__list-item--alt work__list-item--${alt}`
                : `work__list-item work__list-item--${alt}`
            }
            id={i}
          >
            {tech}
          </li>
        ))}
      </>
    );
  };
  return (
    <section
      className={show ? "work work--alt" : "work"}
      onWheel={handleClose}
      key={keyId}
    >
      <article
        className={show ? "work__project work__project--alt" : "work__project"}
        id={project.id}
      >
        <div
          className={
            toggleDarkMode
              ? "work__cont work__cont--dark"
              : `work__cont work__cont--${alt}`
          }
        >
          <ImageCarousel imageArray={imageArray} />

          <div
            className={
              toggleDarkMode
                ? "work__overlay work__overlay--dark"
                : "work__overlay"
            }
          >
            <a href={project.github} className="work__link">
              <CodeBracketIcon
                className={
                  toggleDarkMode ? "work__icon work__icon--dark" : "work__icon"
                }
                title="View Code"
              />
            </a>
            {!show &&
              (project && project.video ? (
                <div className="work__button" onClick={handleShow}>
                  <EyeIcon
                    className={
                      toggleDarkMode
                        ? "work__icon work__icon--dark"
                        : "work__icon"
                    }
                    title="View Demo"
                  />
                </div>
              ) : (
                <a className="work__link" href={project.link}>
                  <EyeIcon
                    className={
                      toggleDarkMode
                        ? "work__icon work__icon--dark"
                        : "work__icon"
                    }
                    title="Visit Site"
                  />
                </a>
              ))}
          </div>
        </div>
        {show && project.video ? (
          <div className="work__modal-overlay">
            <div
              className="work__button work__button--alt"
              onClick={handleClose}
            >
              <XCircleIcon
                className={
                  toggleDarkMode
                    ? "work__icon work__icon--alt work__icon--dark"
                    : "work__icon work__icon--alt"
                }
              />
            </div>

            <span className="loader"></span>
            <iframe
              alt="demo video"
              title="Video of app demo"
              src="https://www.loom.com/embed/be035be7c50944c9ae500112055dee57?sid=cc5e05ba-70dc-4a94-ab32-cc7234a00aef?hide_owner=true&hide_share=true&hide_title=true&hideEmbedTopBar=true&?hideFullScreenButton=true"
              webkitallowfullscreen="true"
              mozallowfullscreen="true"
              allowFullScreen={true}
              className="work__modal"
              loading="lazy"
            ></iframe>
          </div>
        ) : (
          ""
        )}

        <div
          className={
            toggleDarkMode
              ? `work__info work__info--dark`
              : `work__info work__info--${alt}`
          }
        >
          {!show && (
            <div className="work__logo-container">
              <h2
                className={
                  toggleDarkMode
                    ? `work__logo work__logo--dark work__logo--${alt}`
                    : `work__logo work__logo--${alt}`
                }
              >
                {project.title}
              </h2>
            </div>
          )}
          <ul
            className={
              show
                ? `work__list work__list--alt work__list--${alt}`
                : `work__list work__list--${alt}`
            }
          >
            {renderTech()}
          </ul>
          {!show && <p className="work__bio">{project.bio}</p>}
        </div>
      </article>
    </section>
  );
}

export default Work;
