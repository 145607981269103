import "../App.scss";
import { useState, useEffect } from "react";
import { useRef } from "react";
import React from "react";
import { ThemeProvider } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import "@fontsource-variable/inter";
import Contact from "../components/Contact/Contact";
import Work from "../components/Work/Work";
import About from "../components/About/About";
import ReactFullpage from "@fullpage/react-fullpage";
import fullpage from "fullpage.js/dist/fullpage.extensions.min.js";
import Header from "../components/Header/Header";
import Profile from "../components/Profile/Profile";
import { useNavigate } from "react-router-dom";
import WorkCarousel from "../components/WorkCarousel/WorkCarousel";

export default function Landing({
  toggleDarkMode,
  toggleDarkTheme,
  darkPrefsCheck,
  darkTheme,
  dimensions,
}) {
  const [show, setShow] = useState(false);
  const nav = useNavigate();

  const fullPageRef = useRef(null);

  const handleShow = () => {
    const lastActiveSection = window.fullpage_api.getActiveSection();

    if (lastActiveSection != null) {
      sessionStorage.setItem("lastActiveSection", lastActiveSection.anchor);
    }
    nav("/blink");
  };
  const handleClose = () => {
    setShow(false);
  };

  const setActive = async (array) => {
    if (window.fullpage_api) {
      const active = await window.fullpage_api.getActiveSection();
      if (active) {
        for (let i = 0; i < array.length; i++) {
          if (array[i].getAttribute("data-menuanchor") === active.anchor) {
            array[i].classList.add("active");
          } else {
            array[i].classList.remove("active");
          }
        }
      }
    }
  };

  const initialiseFullPage = () => {
    new fullpage("#fullpage", {
      scrollingSpeed: 1000,
      licenseKey: process.env.REACT_APP_LICENSE_KEY,
      credits: { enabled: false, label: "", position: "right" },
      dragAndMove: true,
      // navigation: true,
      // navigationPosition: "right",
      // touchSensitivty: 1,
      navigationColor: "#22223b",
      lazyLoading: false,
      slidesNavigation: true,
      setFitToSection: false,
      menu: "#myMenu",
    });
  };
  const destroyFullPage = () => {
    if (fullPageRef) {
      window.fullpage_api.destroy("all");
    }
  };

  const fullPageReset = () => {
    destroyFullPage(); // Remove FullPage.js instance
    initialiseFullPage(); // Reinitialize FullPage.js after resizing
  };

  useEffect(() => {
    darkPrefsCheck();
    initialiseFullPage(); // Initial FullPage.js setup
    const lastActive = sessionStorage.getItem("lastActiveSection");
    if (lastActive === "work") {
      window.fullpage_api.silentMoveTo(lastActive);
      setTimeout(() => {
        sessionStorage.removeItem("lastActiveSection");
      }, 1000);
    }
    window.addEventListener("resize", fullPageReset);

    return () => {
      destroyFullPage();
      window.removeEventListener("resize", fullPageReset);
    };
  }, []);

  // FOR MOBILE/TABLET REDIRECTION
  useEffect(() => {
    const active = window.fullpage_api.getActiveSection();
    if (dimensions.width > 768 && active.anchor === "profile") {
      window.fullpage_api.moveTo("about");
    }

    if (dimensions.width < 768 && active.anchor === "about") {
      window.fullpage_api.moveTo("profile");
    }
  }, [dimensions.width]);

  useEffect(() => {
    const ulElement = document.querySelector("#myMenu");
    if (ulElement) {
      setActive(ulElement.children);
    }
  }, [dimensions.width, toggleDarkMode]);

  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />
      <div className={toggleDarkMode ? "app" : "app app--alt"}>
        <Header
          toggleDarkTheme={toggleDarkTheme}
          toggleDarkMode={toggleDarkMode}
          dimensions={dimensions}
          handleClose={handleClose}
        />
        {dimensions.width > 768 && (
          <div
            className={
              toggleDarkMode
                ? "app__container"
                : "app__container app__container--alt"
            }
          >
            <Profile toggleDarkMode={toggleDarkMode} />
          </div>
        )}
        <div id="fullpage" className="app__page" ref={fullPageRef}>
          <div className="app__sections-container">
            {dimensions.width <= 768 && (
              <div className="section app__section" data-anchor="profile">
                <div
                  className={
                    toggleDarkMode
                      ? "app__container"
                      : "app__container app__container--alt"
                  }
                >
                  <Profile toggleDarkMode={toggleDarkMode} />
                </div>
              </div>
            )}
            <div className="section app__section" data-anchor="about">
              <About toggleDarkMode={toggleDarkMode} />
            </div>
            <div
              className={
                show
                  ? "section app__section app__section--work app__section--alt"
                  : "section app__section app__section--work "
              }
              data-anchor="work"
            >
              <WorkCarousel
                toggleDarkMode={toggleDarkMode}
                show={show}
                handleShow={handleShow}
                handleClose={handleClose}
              />
            </div>
            <div className="section app__section" data-anchor="contact">
              <Contact toggleDarkMode={toggleDarkMode} />
            </div>
          </div>
        </div>
      </div>
    </ThemeProvider>
  );
}
