export const stack = [
  { label: "javascript", name: "JavaScript" },
  { label: "typescript", name: "TypeScript" },
  { label: "html", name: "HTML 5" },
  { label: "css", name: "CSS 3" },
  { label: "express", name: "Express" },
  { label: "react", name: "React" },
  { label: "postgresql", name: "PostgreSQL" },
  { label: "nextjs", name: "Next.js" },
  { label: "nodejs", name: "Node.js" },
  { label: "mysql", name: "MySQL" },
  { label: "tailwindcss", name: "Tailwind CSS" },
  { label: "scss", name: "SCSS" },
];

export const projectBlink = {
  id: 1,
  title: "BLINK",
  bio: "A full-stack web application for organising last-minute plans with friends. Features include user authentication, user image upload and 'active now' functionality.",
  stack: ["JavaScript", "React", "MySql", "Express", "Firebase"],

  video:
    "https://www.loom.com/embed/be035be7c50944c9ae500112055dee57?sid=cc5e05ba-70dc-4a94-ab32-cc7234a00aef?hide_owner=true&hide_share=true&hide_title=true&hideEmbedTopBar=true&?hideFullScreenButton=true",
  github: "https://github.com/louisohara/louis-ohara-blink",
};

export const projectAshley = {
  id: 2,
  title: "ASHLEY FRANCIS-ROY",
  bio: "A full-stack web application built for documentary filmmaker Ashley Francis-Roy to showcase his portfolio of films. ",
  stack: ["TypeScript", "React", "MySql", "Express"],
  link: "https://ashfranroy.co.uk",
  github: "https://github.com/louisohara/ashley-portfolio-frontend",
};

export const projectTask = {
  id: 3,
  title: "TASK-TRACK",
  bio: "A full-stack task tracking application built using Next.js React framework. Features include CRUD operations and calendar display.",
  stack: ["TypeScript", "Next.js", "PostgreSQL", "Node.js"],
  link: "https://nextjs-demo-self-tau.vercel.app",
  github:
    "https://github.com/louisohara/task-track/tree/main/Documents/CODING/NextJS/nextjs-dashboard",
};

export const projectArcca = {
  id: 4,
  title: "CURRENT PROJECT",
  bio: "A website I'm currently developing for Arcca Magazine in Next.js, using animation libraries such as GSAP and framer motion.",
  stack: ["TypeScript", "Next.js", "GSAP", "Framer"],
  link: "https://magazine-omega-opal.vercel.app/",
  github: "https://github.com/louisohara/magazine",
};
