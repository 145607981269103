// import "./App.scss";
import { useState, useEffect } from "react";
import React from "react";
import { ThemeProvider, createTheme } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import "@fontsource-variable/inter";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Landing from "./pages/Landing.js";
import Video from "./pages/Video.js";
import WorkCarousel from "./components/WorkCarousel/WorkCarousel";

export default function App() {
  const [toggleDarkMode, setToggleDarkMode] = useState(false);
  const [dimensions, setDimensions] = useState({
    height: window.innerHeight,
    width: window.innerWidth,
  });

  const darkTheme = createTheme({
    palette: {
      mode: toggleDarkMode ? "dark" : "light",
    },
  });

  const toggleDarkTheme = () => {
    setToggleDarkMode((prevDarkMode) => {
      const newDarkMode = !prevDarkMode;
      sessionStorage.setItem("darkPrefs", newDarkMode);
      return newDarkMode;
    });
  };

  const darkPrefsCheck = () => {
    const darkPrefs = sessionStorage.getItem("darkPrefs");
    if (darkPrefs !== null) {
      setToggleDarkMode(darkPrefs === "true");
    }
  };

  const handleResize = () => {
    setDimensions({
      height: window.innerHeight,
      width: window.innerWidth,
    });
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />

      <BrowserRouter>
        <Routes>
          <Route
            path="/"
            element={
              <Landing
                toggleDarkMode={toggleDarkMode}
                toggleDarkTheme={toggleDarkTheme}
                darkPrefsCheck={darkPrefsCheck}
                darkTheme={darkTheme}
                dimensions={dimensions}
              />
            }
          />
          <Route
            path="/blink"
            element={
              <Video
                toggleDarkMode={toggleDarkMode}
                toggleDarkTheme={toggleDarkTheme}
                darkPrefsCheck={darkPrefsCheck}
                darkTheme={darkTheme}
                dimensions={dimensions}
              />
            }
          />
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  );
}
